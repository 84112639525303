<style lang="less" scoped>
.about-us {
  width: 100%;
  background: #070911;
  &-second {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 42.2%;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        letter-spacing: 4px;
        p {
          margin: 0;
          &:nth-child(1) {
            font-size: 30px;
            line-height: 42px;
          }
          &:nth-child(2) {
            font-size: 10px;
            line-height: 14px;
            margin: 5px 0 28px;
          }
          &:nth-child(3) {
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="about-us">
    <div class="about-us-second">
      <div class="about-us-second-container">
        <div class="about-us-second-container-left">
          <img src="../assets/Bitmap.png"
               alt="">
        </div>
        <div class="about-us-second-container-right">
          <p>{{$t('Q27')}}</p>
          <p>{{$t('Q28')}}</p>
          <p>
            {{$t('Q29')}}
          </p>
          <div class="r-button"
               @click="toAboutUsDetails">
            {{$t('readMore')}}
          </div>
        </div>
      </div>
    </div>
    <div class="about-us-second">
      <div class="about-us-second-container">
        <div class="about-us-second-container-left">
          <img src="../assets/fearless/fearless_2.png"
               alt="">
        </div>
        <div class="about-us-second-container-right">
          <!-- <p>ABOUT Wuwei</p> -->
          <p>{{ $t('aboutWuwei') }}</p>
          <p></p>
          <p>
            {{ $t('Wuwei') }}
            <!-- Shanghai Wuwei Creative Economy Region Cooperation Promotion Centre is supported by Prof Wuwei Li, the Founder of Chinese Creative Economy, and the Vice-Chairman of the 11th Chinese People's Political Consultative Conference, initiated by Yangtze River Delta Creative Economy Cooperation Committee (CECC).
            The Wuwei Centre is a non-governmental organisation that provides a platform for business, non-profit organisations, professionals and young people from all countries to promote the idea of a creative economy and support domestic and international collaboration. -->
          </p>
          <!-- <p>{{$t('Q28')}}</p>
          <p>
            {{$t('Q29')}}
          </p> -->
          <!-- <div class="r-button"
               @click="toAboutUsDetails">
            {{$t('readMore')}}
          </div> -->
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';
import CommonActivies from '../components/common-activities';

export default {
  name: 'about-us',
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') !== 'en') this.$router.push({
      name: 'aboutUs'
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    toAboutUsDetails () {
      this.$router.push({
        name: 'aboutUs/details'
      })
    },
    openNewLink (link) {
      window.open(link, '_blank');
    },
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  },
}
</script>
